let recipeNavLinks = $("nav.recipe-navigation ul li a");
let browseToggleLinks = $(".button-group a");
let browsePanels = $(".browse-panel");

// Highlight appropriate recipe nav link on scroll
$(window).scroll(function () {
  let scrollPos = window.scrollY;
  if (scrollPos > 285) {
    recipeNavLinks.each(function (i) {
      let section_id = $(this).prop("hash");
      let section = $(section_id);
      let sectionScrollTop = section.offset().top;
      let sectionHeight = section.outerHeight(true);

      if (sectionScrollTop <= scrollPos + 1 && sectionScrollTop + sectionHeight > scrollPos + 1) {
        $(this).parent().addClass("is-active");
      } else {
        $(this).parent().removeClass("is-active");
      }
    });
  }
});

// Scroll to appropriate section on click from a recipe nav link
recipeNavLinks.each(function (i) {
  $(this).click(function (event) {
    event.preventDefault();
    let target = document.querySelector(event.target.hash);
    target.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  });
});

// Scroll to appropriate section on click from a recipe nav link
browseToggleLinks.each(function (i) {
  $(this).click(function (event) {
    event.preventDefault();
    let browsePanelID = $(this).attr("href");
    browsePanels.hide();
    browseToggleLinks.removeClass("active");
    $(this).addClass("active");
    $(browsePanelID).show();
  });
});

let initialiseBrowsePanels = function () {
  browsePanels.hide();
  $("#charitypatterns").show();
};

let init = function () {
  initialiseBrowsePanels();
};

init();

// Expanders
$(".expander__trigger").on("click", function () {
  const $this = $(this);
  const parent = $this.parents(".expander");
  const body = parent.find(".expander__body");

  if (parent.hasClass("expander--expanded")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass("expander--expanded");
});

$(".guide-contributor__trigger").on("click", function () {
  const $this = $(this);
  const parent = $this.parents(".guide-contributor");
  const body = parent.find(".guide-contributor__body");

  if (parent.hasClass("guide-contributor--expanded")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass("guide-contributor--expanded");
});

$("body").on("click", "[data-link]", function () {
  var linkUrl = $(this).data("link");
  window.location.href = linkUrl;
});

// Accessibility keyboard
$(".expander__trigger").keydown(function(e) {
  const $this = $(this);
  const parentExpander = $this.parents(".expander");
  const code = e.keyCode || e.which;

  // up
  if(code == 38) {
    const previousExpander = parentExpander.prev(".expander").find(".expander__trigger");
    previousExpander.focus();
  }

  // down
  if(code == 40) {
    const nextExpander = parentExpander.next(".expander").find(".expander__trigger");
    nextExpander.focus();
  }

  // home
  if(code == 36) {
    $(".expander__trigger").first().focus();
  }

  // end
  if(code == 35) {
    $(".expander__trigger").last().focus();
  }
});

// Mobile nav
$("#menubutton").on("click", function() {
  const primaryNav = $(".primary-navigation");

  if(!primaryNav.hasClass("primary-navigation--open")) {
    $(this).addClass("menu-button--open");
    primaryNav.css("display", "block");
    primaryNav.removeClass("primary-navigation--closed");
    primaryNav.addClass("primary-navigation--open");
  } else {
    $(this).removeClass("menu-button--open");
    primaryNav.removeClass("primary-navigation--open");
    primaryNav.addClass("primary-navigation--closed");
    setTimeout(function() {
      primaryNav.css("display", "none");
    }, 200);
  }
});